import {
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
  redirect,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { sanitizeJson } from "@utils";
import stylesheet from "../../../packages/design-system/src/assets/styles/globals.css?url";
import { AuthProvider } from "./hooks/useAuth";
import { CurrentUserProvider } from "./hooks/useCurrentUser";
import Layout from "./layout";
import { FIREBASE_CONFIG, FIRESTORE_ROOT_DEVELOPER } from "./libs/publicEnv.server";
import { currentUserMiddleware } from "./middlewares/currentUserMiddleware";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
  { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
  { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
  { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
  { rel: "manifest", href: "/manifest.json", crossOrigin: "use-credentials" },
  { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#312e81" },
];

export const meta: MetaFunction = () => {
  return [
    { title: "HubDesk" },
    { name: "description", content: "Welcome to Remix!" },
    { name: "msapplication-TileColor", content: "#f9f9f9f" },
    { name: "theme-color", content: "#ffffff" },
  ];
};

export async function loader({ request, ...args }: LoaderFunctionArgs) {
  const { currentUser, isExpiredToken, isServerLogin } = await currentUserMiddleware({
    request,
    ...args,
  });
  const url = new URL(request.url);
  const isAuthPage =
    url.pathname === "/login" ||
    url.pathname.startsWith("/auth") ||
    url.pathname.startsWith("/setup");

  if (!isServerLogin && !isAuthPage) {
    return redirect("/login");
  }

  return json({
    currentUser: sanitizeJson(currentUser),
    isExpiredToken,
    PUBLIC_ENV: {
      FRONTEND_ENV: process.env.FRONTEND_ENV,
      FIREBASE_CONFIG,
      FIRESTORE_ROOT_DEVELOPER,
    },
  });
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  // biome-ignore lint/suspicious/noConsole: <explanation>
  console.error(error);
  captureRemixErrorBoundaryError(error);
  return (
    <html lang="ja">
      <head>
        <title>Oops!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <h1>
          {isRouteErrorResponse(error)
            ? `${error.status} ${error.statusText}`
            : error instanceof Error
              ? error.message
              : "Unknown Error"}
        </h1>
        <Scripts />
      </body>
    </html>
  );
};

export default function App() {
  const {
    PUBLIC_ENV,
    currentUser: serverCurrentUser,
    isExpiredToken,
  } = useLoaderData<typeof loader>();

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <AuthProvider isExpiredToken={isExpiredToken}>
          <CurrentUserProvider initCurrentUser={serverCurrentUser}>
            <Layout>
              <Outlet />
            </Layout>
          </CurrentUserProvider>
        </AuthProvider>
        <ScrollRestoration />
        <Scripts />
        <script
          // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
          dangerouslySetInnerHTML={{
            __html: `window.PUBLIC_ENV = ${JSON.stringify(PUBLIC_ENV)}`,
          }}
        />
      </body>
    </html>
  );
}
